<template>
	<!--设备台账管理/备品备件-对应原物料管理-->
	<mainBox >
		<div class="wapBox">
			<div >
				<div class="eBtns">
					<el-button class='b_it' @click='exportMateriel()'  >导出 </el-button>
					<el-button class='b_it' @click='uploadModel = true' type="primary" >导入</el-button>
					<el-button class='b_it' @click='downMaterielTemplate'>导入模版</el-button>
					<el-button class='b_it' @click='refreshTable' icon="el-icon-refresh-right">刷新</el-button>
					<el-button class='b_it sxBtn' @click.stop='showRowQuery'>筛选
					 	<i class="el-icon-arrow-down" v-if="rowQuery"></i>
        		<i class="el-icon-arrow-right" v-else></i>
					</el-button>
				</div>
				<div class="tbBox ">
					<TTable2 class='' ref="myTable" :tbloading='tbloading' :border='false' :showQuery='rowQuery' @rowSeach='rowSeach' :tableData="tableData" :tableTitle="tableTitle">
						
						<template slot='tbCode' slot-scope="scope">
							<el-button type="text" @click='tbRowDetail(scope.row)'>{{scope.row[scope.column.property]}}</el-button>
						</template>
						<template slot='endingStatusStr' slot-scope="scope">
							<span :class="'txColor'+scope.row.endingStatus"> {{scope.row.materielStatusStr}}</span>
						</template>
						<template slot='operating' slot-scope="scope">
							
						</template>
					</TTable2>
					<TPagination v-model="tableParams" @initTable="initTable"></TPagination><!-- 分页 -->
				</div>
			</div>
	    	<!--导入文件-->
		    <el-dialog :visible.sync="uploadModel" width="40%">
		      <template slot="title">
		        <title-icon />{{ $t("commons.uploadFile") }}
		      </template>
		      <div slot="footer">
		        <upload
		          :ulType="uploadType"
		          :excelType="excelType"
		          :rootPath="rootPath"
		          @success="uploadSuccess"
		          @fail="uploadFail"
		        ></upload>
		      </div>
		    </el-dialog>
		</div>
	</mainBox>
</template>
<script>
	import pagination from "@/mixins/TTable/pagination";
	import seachAndTable from "@/mixins/TTable/seachAndTable";
	import TTable2 from '@/components/YTable/TTable2.vue';
	import TPagination from '@/components/YTable/TPagination.vue';
	import Upload from "@/components/Upload";
	import { exportMateriel, downMaterielTemplate } from "@/api/business/base/tenant/materiel";
	import { envInfo } from "@/constants/envInfo";
	const statusArrs=[
		//入驻状态 0待审核 1待退租 2待回执 3待结算 4已退租 5已驳回
		{label:'闲置',value:1},
		{label:'使用中 ',value:2},
	]
	export default {
		mixins: [pagination, seachAndTable],
		components: {TTable2,TPagination,Upload},
		data() {
			return {
				tableTitle: [
					{
						name: '备品备件编号',
						prop: "materielCode",
						slotName: "tbCode",
						queryInfo: {
							type: 'input',
							value: "",
						},
					},
					{
						name: '备品备件名称',
						prop: "materielName",
						queryInfo: {
							type: 'input',
							value: "",
						}
					},
					{
						name: '主资产编号',
						prop: "factoryCode",
						slotName: "tbCode",
						queryInfo: {
							type: 'input',
							value: "",
						}
					},
					{
						name: '备品备件型号',
						prop: "currentModel",
					},
					{
						name: '单位',
						prop: "materielUnit",
					},
					{
						name: '金额',
						prop: "materielPrice",
					},
					{
						name: '购入日期',
						prop: "purchaseDate",
					},
					{
						name: '出库单号',
						prop: "deliveryCode",
					},
					{
						name: '状态',
						prop: "materielStatus",
						slotName:'endingStatusStr',
						queryInfo: {
							type:'select',
							value:'',
							options:statusArrs,
						}
					},
					{
						name: '库存',
						prop: "storekeeperName",
					},
					{
						name: '使用人',
						prop: "materielUserName",
						queryInfo: {
							type: 'input',
							value: "",
						}
					},
				
					{name: '说明',prop: "description",},
				],
				tableData: [],
				yyjfInfo: { //预约场地交付
					open: false,
					datas:{},
				},
				//上传相关 -复制之前
				upFiles:{},
				//end
				//导入相关 -复制之前
				uploadModel: false,
				uploadType: "ExcelImport",
		      	excelType: "ruge.materiel",
		      	rootPath: envInfo.bgApp.basePath,
				//导入end
			};
			},
			watch: {},
				created() {
				},
				mounted() {},
				computed: {},
				methods: {
					tbRowDetail(row) {
						let params = {
							path: "/tenant/base/materiel/partsDetail",
							query: {
								dataId: row ? row.materielId : '',
							},
						};
						this.$router.push(params);
					},
					toAddRetreat(row) {
						let params = {
							path: "/rwork/enterprise/toRetreat",
							query: {
								rentEndingId: row ? row.rentEndingId : '',
							},
						};
						this.$router.push(params);
					},
					async initTable() {
						let params = {
							current: this.tableParams.current,
							rowCount: this.tableParams.rowCount,
							...this.rowFilterData,
						}
						for(let keys in params){
							if(!params[keys]) delete params[keys];
							
						}
						let res = await this.ApiHttp('/base/tenant/materiel/page', params);
						res.rows.map(it=>{
							it.materielStatusStr=statusArrs[+it.materielStatus-1].label;
						})
						this.tableData = res.rows||[];
						this.tableParams.total = res.total;
						
						
						this.$nextTick(() => {
							this.tbloading = false;
						})
					},

					delMateriel(row) {
						this.$confirm('是否确认删除', '提示').then(() => {
							this.ApiHttp('/base/tenant/materiel/delete', row, 'delete').then(res => {
								if(res) {
									this.initTable();
								}
							});

						}).catch(() => {});
					},
					exportMateriel() {
				 		let params = {
							current: this.tableParams.current,
							rowCount: this.tableParams.rowCount,
							...this.rowFilterData,
						}
				      	exportMateriel(params).then((msg) => {
					        this.$message({
					          type: "success",
					          message: this.$t("message.operationSuccess"),
					        });
					        let exportObj = {
					          taskId: msg,
					          taskName: "Materiel",
					          taskStatus: 0,
					          rootPath: "basePath",
					        };
				        	//将导出任务丢入导出任务列表中
				        	this.$store.dispatch("PushExportNotice", exportObj);
				      });
	    			},
	    			downMaterielTemplate() {//导入模版
				      downMaterielTemplate();
				    },
	    			uploadSuccess() {
			      		this.uploadModel = false;
				    },
				    uploadFail() {
				      	this.$message({ type: "error", message: this.$t("message.uploadFial") });
				    },

				},
			};</script>

<style scoped lang="scss">
	.wapBox {
		height: 100%;
		.flexs {
			height: 100%;
		}

		.right {
			padding: 16px 24px;
			.eBtns {
				position: relative;
				.el-button {
					padding: 10px 18px;
				}
				.sxBtn{
					position: absolute;
					right: 0;
				}
			}
		}
		.txColor0{
	    padding: 0 6px;
	   	color: #4e78ff;
    	background: #f4f6fe;
		}
		.txColor4{
	    padding: 0 6px;
	   	color: #78d549;
    	background: #f2faee;
		}
		.txColor5{
	    padding: 0 6px;
	   	color: #e8331c;
  		background: #fef5f4;
		}
	}
</style>
<style type="text/css">
	.tbTreeCheck thead .el-checkbox {
		display: none;
	}
	
	.materielBox .m-body {
		padding: 0 !important;
	}
	.dfNode1 {
		.el-tree-node__label {
			color: #1A4CEC;
		}
	}
	
	.drBtn.el-button--text {
		color: #2F3941 !important;
	}
</style>